import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material'
import { ONE_ONE_QUESTION_COLLECTION, ONE_ONE_TOPIC_COLLECTION } from '../../_constants/globals'
import ModalEditQuestion from './ModalEditQuestion'
import ModalDelete from '../_library/ModalDelete'
import { listenCollection } from '../../store/actions/firestoreActions'
import Page from '../_library/Page'
import useListener from '../../hooks/useListener'
import MUIDataTable from 'mui-datatables'
import { Add, Delete, Edit } from '@mui/icons-material'
import { format } from 'date-fns'


const PROFILE_TYPES = [
  'ACI',
  'ACE',
  'AFI',
  'AFE',
  'CAI',
  'CAE',
  'CMI',
  'CME',
  'FAI',
  'FAE',
  'FMI',
  'FME',
  'MCI',
  'MCE',
  'MFI',
  'MFE',
]
export const QuestionPage = () => {
  useListener(() => listenCollection({ collection: ONE_ONE_QUESTION_COLLECTION }))
  useListener(() => listenCollection({ collection: ONE_ONE_TOPIC_COLLECTION }))
  const questions = useSelector(state => state.firestore.oneOneQuestions)
  
  const [editQuestion, setEditQuestion] = useState(null)
  const [deleteQuestionId, setDeleteQuestionId] = useState(null)
  
  const data = useMemo(() =>
    questions?.map(({ id, title, customTitle, onlyApplyForProfiles, oneOneTopic, order, _createdAtTime }) => oneOneTopic.slug === 'custom-questions'
      ? [id, onlyApplyForProfiles?.join(', '), oneOneTopic?.slug, order, customTitle, null, null, null, _createdAtTime?.toDate(), id]
      : [id, onlyApplyForProfiles?.join(', '), oneOneTopic?.slug, order, title?.fr?.man, title?.fr?.woman, title?.en?.man, title?.en?.woman, _createdAtTime?.toDate(), id],
    ), [questions])
  
  return (
    <Page withTopBarAndMenu>
      <MUIDataTable
        title='One one Questions'
        data={data}
        columns={[
          {
            name: 'id',
            options: {
              filter: false,
            },
          },
          {
            name: 'ApplyFor',
            options: {
              filterList: [],
              filterType: 'custom',
              customFilterListOptions: {
                render: v => v.map(l => l.toUpperCase()),
                update: (filterList, filterPos, index) => {
                  filterList[index].splice(filterPos, 1)
                  return filterList
                },
              },
              filterOptions: {
                logic: (onlyApplyForProfiles, filters) => {
                  if (filters.length) return !filters.find(p => onlyApplyForProfiles?.includes(p))
                  return false
                },
                display: (filterList, onChange, index, column) =>
                  <FormControl variant='standard'>
                    <InputLabel>ApplyFor</InputLabel>
                    <Select
                      variant='standard'
                      multiple
                      value={filterList[index]}
                      renderValue={selected => selected.join(', ')}
                      onChange={event => {
                        filterList[index] = event.target.value
                        onChange(filterList[index], index, column)
                      }}>
                      {PROFILE_TYPES.map(item => (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            color='primary'
                            checked={filterList[index].indexOf(item) > -1}
                          />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>,
              },
            },
          },
          {
            name: 'Topic',
            options: {
              filterType: 'multiselect',
            },
          },
          { name: 'Order' },
          {
            name: 'Title FR man',
            options: {
              filter: false,
            },
          },
          {
            name: 'Title FR woman',
            options: {
              filter: false,
            },
          },
          {
            name: 'Title EN man',
            options: {
              filter: false,
            },
          },
          {
            name: 'Title EN woman',
            options: {
              filter: false,
            },
          },
          { name: 'CreatedAt', options: { display: 'false', customBodyRender: d => d && format(d, 'dd/MM/yyyy') } },
          {
            name: 'Actions',
            options: {
              filter: false,
              sort: false,
              customBodyRender: id => <Box sx={{ display: 'flex' }}>
                <IconButton onClick={() => setEditQuestion(questions.find(q => q.id === id))} sx={{ color: 'primary.main' }}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => setDeleteQuestionId(id)} sx={{ color: 'secondary.main' }}>
                  <Delete />
                </IconButton>
              </Box>,
            },
          },
        ]}
        options={{
          customToolbar: () => (<IconButton onClick={() => setEditQuestion({})}><Add /></IconButton>),
          rowsPerPageOptions: [10, 50, 100],
          selectableRows: 'none',
        }}
      />
      {editQuestion && <ModalEditQuestion question={editQuestion} setEditQuestion={setEditQuestion} />}
      {deleteQuestionId && <ModalDelete collection={ONE_ONE_QUESTION_COLLECTION} id={deleteQuestionId} callback={() => setDeleteQuestionId(null)} /> }
    </Page>
  )
}

export default QuestionPage
