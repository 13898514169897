import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select,
  Typography, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import DeleteButton from '../_library/DeleteButton'
import InlineEditor from '../_library/InlineEditor'
import Loading from '../_library/Loading'
import { isValid } from 'date-fns'
import PersonalityGraph from './PersonalityGraph'
import { getTeamCSVFile, getTeamProfileType } from '../../_helpers/helpers'
import CircularProgress from '@mui/material/CircularProgress'
import html2canvas from 'html2canvas'
import { getSafeCocoonPPTX, getTeamBuildingPPTX } from '../../_helpers/pptxHelper'
import { COMPANY_COLLECTION, CONVERSATION_TYPES, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { deleteDocAction, listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { DatePicker } from '@mui/lab'
import useListener from '../../hooks/useListener'


const labelStyle = {
  color: 'rgba(0, 0, 0, .54)',
  display: 'inline-block',
  lineHeight: '20px',
  width: '200px',
  verticalAlign: 'bottom',
}

const EditTeamDialog = ({ team, onClose }) => {
  
  const companies = useSelector(state => state.firestore.companies)
  const teamMembers = useSelector(state => state.firestore.teamMembers)
  const sumUpCard = useSelector(state => state.firestore.cardTemplates?.find(t => t.type === 'sum-up'))
  const teamProfileCard = useSelector(state => state.firestore.cardTemplates?.find(t => t.type === 'team-profile'))
  const dispatch = useDispatch()
  
  useListener(() => listenCollection({
    collection: USER_COLLECTION,
    where: [['teamRefs', 'array-contains', TEAM_COLLECTION, team.id]],
    storeAs: 'teamMembers',
  }), [team.id])
  
  const [loadingPPT, setLoadingPPT] = useState(false)
  const [loadingTeam, setLoadingTeam] = useState(false)
  
  const company = useMemo(() => companies?.find(c => c.id === team.companyRef.id), [companies, team])
  const teamProfile = useMemo(() => getTeamProfileType(teamMembers?.filter(u => u?.testResults)), [teamMembers])
  
  const updateTeamAction = (id, data) => dispatch(updateDocAction(TEAM_COLLECTION, id, data))
  
  const downloadFile = async (type = 'teamBuilding') => {
    setLoadingPPT(true)
    const users = teamMembers.filter(m => m.profileType)
    const graphImage = await new Promise(resolve => {
      setTimeout(async () => {
        const canvas = await html2canvas(window.document.querySelector('#personalityGraph'), { useCORS: true })
        resolve(canvas.toDataURL())
      }, 1000)
    })
    const graphImageBg = await new Promise(resolve => {
      setTimeout(async () => {
        const canvas = await html2canvas(window.document.querySelector('#personalityGraphBg'), { useCORS: true })
        resolve(canvas.toDataURL())
      }, 1000)
    })
    if (type === CONVERSATION_TYPES.TEAM_BUILDING)
      getTeamBuildingPPTX('admin', company.name, team.name, users, sumUpCard, team.id, graphImage, graphImageBg, teamProfileCard)
        .catch(err => { console.error(err) })
        .finally(() => { setLoadingPPT(false) })
    else getSafeCocoonPPTX('admin', company.name, team, users, sumUpCard)
      .catch(err => { console.error(err) })
      .finally(() => { setLoadingPPT(false) })
  }
  
  const downloadCSV = useCallback(() => {
    setLoadingTeam(true)
    const file = new Blob([getTeamCSVFile(team, teamMembers, teamProfile)], { type: 'text/csv' })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = team.name + Date.now() + '.csv'
    document.body.appendChild(element)
    element.click()
    setLoadingTeam(false)
  }, [teamProfile, teamMembers])
  
  if (!team) return <Loading />
  else return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiInput-underline::before': { borderBottomColor: 'rgba(0, 0, 0, .1)' },
    }}>
      <DialogTitle id='form-dialog-title' sx={{ '& > small': { fontSize: '.9rem', color: 'grey.700' } }}>
        {team.name}&nbsp;<small>{team.id}</small>
      </DialogTitle>
      <DialogContent>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 4 }}>
          <CardContent sx={{ '&:last-child': { pb: 'initial' } }}>
            <Grid item xs={12} mb={1}>
              <InlineEditor title='Nom' value={team.name} handleSubmit={val => dispatch(updateTeamAction(team.id, { name: val }))} />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Date de création
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                margin='dense'
                id='_createdAtTime'
                value={team._createdAtTime ? team._createdAtTime.toDate() : new Date()}
                onChange={val => isValid(val) && updateTeamAction(team.id, { _createdAtTime: val })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Client
              </Typography>
              <FormControl variant='outlined'>
                <Select
                  id='company-select'
                  value={team.companyRef?.id || ''}
                  onChange={e => updateTeamAction(team.id, { companyRef: [COMPANY_COLLECTION, e.target.value] })}
                  margin='dense'
                  sx={{ minWidth: '200px' }}
                  inputProps={{ sx: { p: 1 } }}
                >
                  {companies?.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Manager
              </Typography>
              <FormControl variant='outlined'>
                <Select
                  id='manager-select'
                  value={team.managerRefs[0].id || ''}
                  onChange={e => updateTeamAction(team.id, { managerRef: [USER_COLLECTION, e.target.value] })}
                  margin='dense'
                  sx={{ minWidth: '200px' }}
                  inputProps={{ sx: { p: 1 } }}
                >
                  {teamMembers?.map(u => <MenuItem key={u.id} value={u.id}>{u.email}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Membres
              </Typography>
              {teamMembers?.map(user => <Typography key={user.id} style={{ marginLeft: 200 }}>
                {`${user.profileType} - ${user.email}` || user.id}
              </Typography>)}
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Profile d&apos;équipe
              </Typography>
              <Typography style={{ display: 'inline' }}>
                {teamMembers && getTeamProfileType(teamMembers.filter(u => u?.testResults))}
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Feu de camp 1
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='safeCocoon'
                value={team[CONVERSATION_TYPES.SAFE_COCOON] ? team[CONVERSATION_TYPES.SAFE_COCOON].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.SAFE_COCOON]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Feu de camp 2
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='teamBuilding'
                value={team[CONVERSATION_TYPES.TEAM_BUILDING] ? team[CONVERSATION_TYPES.TEAM_BUILDING].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.TEAM_BUILDING]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Camp de base 1
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='campBase1'
                value={team[CONVERSATION_TYPES.CAMPBASE_1] ? team[CONVERSATION_TYPES.CAMPBASE_1].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.CAMPBASE_1]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Camp de base 2
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='campBase2'
                value={team[CONVERSATION_TYPES.CAMPBASE_2] ? team[CONVERSATION_TYPES.CAMPBASE_2].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.CAMPBASE_2]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Camp de base 3
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='campBase3'
                value={team[CONVERSATION_TYPES.CAMPBASE_3] ? team[CONVERSATION_TYPES.CAMPBASE_3].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.CAMPBASE_3]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>
                Camp de base 4
              </Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='campBase4'
                value={team[CONVERSATION_TYPES.CAMPBASE_4] ? team[CONVERSATION_TYPES.CAMPBASE_4].toDate() : null}
                onChange={val => isValid(val) && dispatch(updateTeamAction(team.id, { [CONVERSATION_TYPES.CAMPBASE_4]: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
          </CardContent>
        </Card>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 4 }}>
          <CardContent sx={{ '&:last-child': { paddingBottom: 'initial' } }}>
            {teamMembers && <PersonalityGraph
              users={teamMembers.filter(u => u?.testResults)}
              displayAvatars
              displayHeart
            />}
          </CardContent>
        </Card>
        <Button onClick={() => downloadFile('safeCocoon')} disabled={loadingPPT} sx={{
          width: '100%',
          bgcolor: 'secondary.main',
          color: 'white',
          fontWeight: 700,
          border: 'solid lightgrey 1px',
        }}>
          {loadingPPT ? <CircularProgress /> : 'PPT feu de camp 1'}
        </Button>
        <Button onClick={() => downloadFile('teamBuilding')} disabled={loadingPPT} sx={{
          width: '100%',
          bgcolor: 'secondary.main',
          color: 'white',
          fontWeight: 700,
          border: 'solid lightgrey 1px',
        }}>
          {loadingPPT ? <CircularProgress /> : 'PPT feu de camp 2'}
        </Button>
        <Button onClick={downloadCSV} disabled={loadingTeam} sx={{
          width: '100%',
          bgcolor: 'primary.main',
          color: 'white',
          fontWeight: 700,
          border: 'solid lightgrey 1px',
        }}>
          {loadingTeam ? <CircularProgress /> : 'Team CSV'}
        </Button>
        {loadingPPT && <PersonalityGraph
          id='personalityGraph'
          users={teamMembers.filter(m => m.profileType)}
          displayAvatars
          displayHeart
          style={{ position: 'fixed' }}
        />}
        {loadingPPT && <PersonalityGraph
          id='personalityGraphBg'
          users={teamMembers.filter(m => m.profileType)}
          displayAvatars
          displayHeart
          style={{ position: 'fixed', backgroundColor: '#F9EDCF' }}
        />}
      </DialogContent>
      <DialogActions>
        <DeleteButton deleteAction={() => dispatch(deleteDocAction(TEAM_COLLECTION, team.id)).then(onClose)} text='Supprimer' />
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditTeamDialog.propTypes = {
  team: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditTeamDialog
